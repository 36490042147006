import React from 'react'
import {css} from '@emotion/core'
import {rhythm} from 'src/styles/util'

import styled from '@emotion/styled'
import Img from 'gatsby-image';
import { ButtonLink } from './Button';

const breakpoint = '850px';

const Section = styled.section`
  display: flex;
  flex-direction: ${p => p.align == 'right' ? 'row' : 'row-reverse'};
  @media (max-width: ${breakpoint}) {
    display: block;
  }
  /* margin-bottom: ${rhythm(1)} */
`
const imgStyle = css`
  /* max-width: 100%;
  height: auto;
  display: block; */
  flex-basis: 62%;
`
const TextWrap = styled.div`
  position: relative;
  flex-basis: 38%;
  display: flex;
  order: ${p => p.align === 'right' ? 2 : 1};

  border: 0 solid;
  ${p => p.first && 'border-top-width: 2px;'}
  ${p => p.last && 'border-bottom-width: 2px;'}

  &:before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 2px; bottom: 2px;
    border: 0 solid;
    ${p => p.first && 'border-top-width: 2px;'}
    ${p => p.last && 'border-bottom-width: 2px;'}
    z-index: -1;
  }

  @media (max-width: ${breakpoint}) {
    border-width: 0;
    &:before { content: none; }
  }
`
const Text = styled.div`
  text-align: center;
  padding: 0 ${rhythm(1)};
  align-self: center;
`
const ImgSection = ({title, body, img, link, align, first, last}) => {
  return (
    <Section align={align}>
      <TextWrap first={first} last={last}>
        <Text>
          <h2>{title}</h2>
          <p>{body}</p>
          {link && link.path && 
            <p><ButtonLink primary="1" to={link.path}>{link.title}</ButtonLink></p>}
        </Text>
      </TextWrap>

      <Img css={imgStyle} fluid={img.childImageSharp.fluid} alt={title} />
    </Section>
  )
}

ImgSection.defaultProps = {
  align: 'right'
}

export default ImgSection;