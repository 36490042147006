import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ImgSection from '../components/ImgSection'

const IndexPage = ({data}) => {
  const {title, description, sections} = data.file.childMarkdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={title} description={description} />

      {sections.map((section, i) =>
        <ImgSection 
          key={section.title}
          {...section}
          align={i%2 ? 'left' : 'right'} 
          first={i == 0}
          last={i == sections.length - 1}
        />)}

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "pages/home.md"}) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          sections {
            title
            body
            img {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link { title, path }
          }
        }
        html
      }
    }
  }
`;
